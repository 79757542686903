:root {
  --blue: #2C82D8;
  --charcoal: #333333;
  --white: #fff;
  --Gray94: #f0f0f0;
  --Gray60: #999999;
  --RowHover: rgba(44, 130, 216, 0.2);
}

::-webkit-scrollbar {
  width: 3px;
  height: 6px;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.1);
}


body {
  font-family: 'Montserrat';
}

.bg-primary {
  background-color: var(--blue) !important;
}

.main-listing {
  width: calc(100% - 61px);
  padding-top: 0px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

@keyframes dropdown-animate {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }

  0% {
    opacity: 0;
  }
}

.top-header .logo h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: var(--charcoal);
  padding: 16px 10px;
  margin-bottom: 0;
}

.top-bar {
  background: var(--white);
  mix-blend-mode: normal;
  box-shadow: 0px 1px 1px rgba(51, 51, 51, 0.1);
}

.top-bar .top-header {
  padding: 10px 20px 10px 10px !important;
}

.top-bar .logo img {
  height: 40px;
  width: 204px;
}

.m_10 {
  margin-bottom: 10px;
}

.mx_20 {
  margin-left: 20px;
  margin-right: 20px;
}

.main-content {
  background: var(--Gray94);
}

.sidebar {
  background: var(--charcoal);
  width: 60px;
}

.gap-lg-3,
.gap-2 {
  gap: 20px !important;
}

.nav-pills .nav-link {
  padding: 20px !important;
  border-radius: 0;
  transition: all 0.2s;
}

.nav-pills .nav-link:hover,
.nav-pills .nav-link:focus,
.nav-pills .nav-link.active {
  background: var(--blue);
}

.notification-counter {
  background-color: var(--blue) !important;
  position: absolute;
  font-size: 8px;
  line-height: 14px;
  text-align: center;
  right: -8px;
  top: 3px;
  width: 20px;
  font-weight: 500;
  padding: 0em .30em;
  height: 15px;
}

.dropdown-menu-arrow::before {
  content: "";
  width: 13px;
  height: 13px;
  background: var(--white);
  position: absolute;
  top: -7px;
  right: 20px;
  transform: rotate(45deg);
  border-top: 1px solid #eaedf1;
  border-left: 1px solid #eaedf1;
}

.dropdown-menu {
  border-radius: 4px;
  padding: 10px 0;
  animation-name: dropdown-animate;
  animation-duration: 0.2s;
  animation-fill-mode: both;
  border: 0;
  box-shadow: 0 5px 30px 0 rgba(82, 63, 105, 0.2);
}

.header-nav .notifications {
  inset: 8px 0 auto auto !important;
}

.header-nav .dropdown-menu .dropdown-header {
  text-align: center;
  font-size: 15px;
  padding: 10px 25px;
}

.configure_page tr td:first-child {
  text-align: center;
}

.header-nav .notifications .notification-item h4 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}

.header-nav .notifications .notification-item p {
  margin-bottom: 0;
  font-size: 13px;
  margin-bottom: 3px;
  color: #919191;
}

.header-nav .notifications .notification-item {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  transition: 0.3s;
}

.nav-profile .user-name {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--Gray60);
  font-family: "Montserrat" !important;
}

.nav-profile .user-tagline {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: right;
  color: var(--Gray60);
  float: right;
  font-family: "Montserrat" !important;
  text-transform: capitalize;
}

.box_wrap_info {
  height: 100vh;
  display: flex;
  align-items: center;
  background-color: #F0F0F0 !important;
}

.box_wrap_info .bg-white,
.swal2-container .swal2-popup {
  overflow: hidden;
  width: 400px;
  border-radius: 4px;
  background-color: #FFF !important;
  padding: 20px;
}

.text_forgot {
  font-family: "Montserrat" !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 17.07px;
  text-decoration: none;
  color: #2C82D8;
}

.login_form .add-new-field {
  border: none;
  border-radius: 4px;
  text-transform: uppercase;
  width: 100%;
}

.login_form .logo {
  width: 204px;
  height: 40px;
  margin-bottom: 15px;
}

.login_form .form-check {
  display: flex;
  align-items: center;
}

.swal2-popup .swal2-html-container {
  display: block;
  font-family: "Montserrat" !important;
  color: var(--charcoal);
  font-weight: 400;
  font-size: 14px;
  margin: 0;
  padding-top: 0px;
}

.breadcrumb-item.active,
.swal2-popup .swal2-title {
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 37px;
  color: var(--charcoal);
  font-family: "Montserrat" !important;
}

.swal2-popup .swal2-title {
  padding-top: 20px;
}

.main-listing .breadcrumb {
  padding: 20px 0px;
  display: flex !important;
  align-content: center;
  margin: 0 !important;
}

.tab-white {
  background-color: var(--white);
  padding: 10px;
}

.configuration-tabs {
  border: 0;
}

.configuration-tabs .nav-link {
  padding: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  border: 0;
  color: var(--charcoal);
}

.add-new-field,
.swal2-actions .swal2-confirm {
  background-color: var(--blue) !important;
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 17px !important;
  box-shadow: none !important;
  padding: 7px 10px !important;
  height: 30px;
  color: #fff;
  border: none;
  border-radius: 3px;
  letter-spacing: inherit !important;
  text-transform: uppercase !important;
}

.add-new-field:hover,
.add-new-field:active {
  background-color: var(--charcoal) !important;
}

.configure-section .MuiPaper-root {
  box-shadow: none !important;
  padding: 0px 20px 20px 20px;
}

.configure_page.configure-section .MuiPaper-root,
.setting_page.configure-section .MuiPaper-root {
  padding-bottom: 8px;
}

.setting__page,
.configuration__page {
  padding: 20px 20px 20px 20px !important;
}

.setting__page .setting_page .MuiPaper-root.MuiPaper-elevation {
  padding: 0;
}

.configure-section .MuiBox-root .add-new-field {
  margin-left: auto;
}

.configure-section .MuiInputBase-root .MuiSelect-select {
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 3px;
}

.configure-section .MuiInputBase-root::before,
.configure-section .MuiInputBase-root::after {
  display: none !important;
}

.edit-btn:hover,
.delete-btn:hover,
.edit-btn:focus,
.delete-btn:focus {
  background-color: transparent !important;
}

.edit-btn span,
.delete-btn span {
  color: var(--blue) !important;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}

.edit-btn .MuiSvgIcon-root,
.delete-btn .MuiSvgIcon-root {
  width: 15px;
  height: 15px;
  color: #999999 !important;
  margin-right: 1px;
}

.configure-section .MuiTableSortLabel-icon {
  opacity: 1 !important;
}

.configure-section .Mui-TableHeadCell-Content-Labels {
  display: flex;
  flex-direction: row-reverse;
  font-family: "Montserrat" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17.07px;
  width: max-content;
}

.configure-section .MuiBadge-root {
  color: #999999;
}

.configure-section .MuiTableContainer-root .MuiTableRow-root.MuiTableRow-head th {
  border: 0;
  color: #333333;
  font-weight: 500;
  padding: 15px 0.5rem;
  vertical-align: middle;
}

.setting__page .MuiTableContainer-root .MuiTableRow-root.MuiTableRow-head th:last-child,
.configure_page .MuiTableContainer-root .MuiTableRow-root.MuiTableRow-head th:last-child {
  width: 200px;
  max-width: 200px;
}

.configure-section .MuiTableContainer-root .MuiTableRow-root.MuiTableRow-head th:nth-child(1) {
  opacity: 0;
}

.configure-section.setting-section .MuiTableContainer-root .MuiTableRow-root.MuiTableRow-head th:nth-child(1) {
  opacity: 1;
}

.configure-section td.MuiTableCell-root {
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  color: var(--charcoal) !important;
  padding: 0.2rem 0.5rem;
  height: 46px;
}

.setting__page td.MuiTableCell-root label,
.setting__page td.MuiTableCell-root,
.setting__page td.MuiTableCell-root label:first-letter,
.setting__page td.MuiTableCell-root:first-letter{
  text-transform: inherit !important;
}

span.active {
  background: #76ee59;
  border-radius: 50px;
  width: 20px;
  height: 20px;
  display: block;
  margin: 0 auto;
}

span.inactive {
  background: red;
  border-radius: 50px;
  width: 20px;
  height: 20px;
  display: block;
  margin: 0 auto;
}

.popup-header {
  background: var(--blue);
  color: #ffffff;
  min-height: 60px;
  height: 60px;
  align-content: center;
}

.popup-header h2,
.popup-header .MuiButtonBase-root {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  padding: 20px !important;
}


.title-select,
.add--fields .col-form-label {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--charcoal);
}

.add--fields .form-control,
.login_form .form-control {
  border-style: solid;
  border-color: #f5f5f5;
  border-radius: 3px 3px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--charcoal);
  width: 100%;
  padding: .375rem .75rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.date_picker .MuiStack-root{  
  overflow: hidden;
  padding: 0;
}
.date_picker .MuiStack-root .MuiFormControl-root{
  min-width: 100%;
  padding: 0;
  height: auto;
}
.date_picker .MuiStack-root input{
  background: #ffffff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--charcoal);
  padding: .375rem .75rem;
  width: 100%;
}

.date_picker .MuiFormControl-root .MuiInputBase-root{
  height: 30px;
}

.date_picker fieldset.MuiOutlinedInput-notchedOutline{
  border-style: solid;
  border-color: #f5f5f5 !important;
  border-width: 1px !important;
}

.form-control:focus,
.date_picker fieldset.MuiOutlinedInput-notchedOutline:hover{
  box-shadow: none;
  border-color: var(--charcoal) !important;
}

.date_picker .MuiButtonBase-root svg{  
  display: none;
}
.date_picker button{  
  position: inherit;
}
.date_picker button:after{
  position: absolute;
  content: '';
  background-image: url(img/date_icon.svg);
  width: 17px;
  height: 17px;
  background-repeat: no-repeat;
  right: 6px;
  top: 50%;
  transform: translateY(-50%);
}
.date_picker button:hover{  
  background-color: transparent !important;
}

.select-dropdown,
.setting-section .form_search [type="time"]{
  background-image: url(img/dropdown-icon.svg) !important;
  background-repeat: no-repeat !important;
  background-position: center right 6px !important;
  background-size: 13px !important;
  padding-right: 20px !important;
}

.select-dropdown .odd {
  background-color: #f5f5f5 !important;
  padding: 10px;
}

.select-dropdown .even {
  background-color: var(--white) !important;
  padding: 10px;
}

.add--fields .MuiStack-root {
  gap: 10px;
}

.add--fields [type="radio"] {
  position: absolute;
  opacity: 0;
  height: 30px;
  width: 50px;
  cursor: pointer;
}

.radio-common {
  position: relative;
  cursor: pointer;
}

/* IMAGE STYLES */
.add--fields [type="radio"]+.img-common {
  cursor: pointer;
  height: 30px;
  width: 50px;
  display: block;
  background-position: center;
}

.add--fields span.img-common {
  background-repeat: no-repeat;
  cursor: pointer;
}

span.img_common {
  height: 25px;
  width: 25px;
  cursor: pointer;
  position: relative;
  display: block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 25px;
  opacity: 1;
}


span.img_common.CAR {
  background-image: url("img/Carhover.svg");
}

span.img_common.TRUCK {
  background-image: url("img/truckhover.svg");
}

span.img_common.BUS {
  background-image: url("img/Bushover.svg");
}

span.img_common.MOTORBIKE {
  background-image: url("img/Motorbikehover.svg");
}

span.img_common.TRAILER {
  background-image: url("img/Trailerhover.svg");
}

span.img_common.TRACTOR {
  background-image: url("img/Tractorhover.svg");
}

span.img_common.BOAT {
  background-image: url("img/Boathover.svg");
}

span.img_common.FORKLIFT {
  background-image: url("img/Forklifthover.svg");
}

span.img_common.TRAIN {
  background-image: url("img/Trainhover.svg");
}

span.img_common.EXCAVATOR {
  background-image: url("img/Excavatorhover.svg");
}

.add--fields span.img-common.car {
  background-image: url("img/Car.svg");
}

.add--fields span.img-common.truck {
  background-image: url("img/truck.svg");
}

.add--fields span.img-common.bus {
  background-image: url("img/Bus.svg");
}

.add--fields span.img-common.motorbike {
  background-image: url("img/Motorbike.svg");
}

.add--fields span.img-common.trailer {
  background-image: url("img/Trailer.svg");
}

.add--fields span.img-common.tractor {
  background-image: url("img/Tractor.svg");
}

.add--fields span.img-common.boat {
  background-image: url("img/Boat.svg");
}

.add--fields span.img-common.forklift {
  background-image: url("img/Forklift.svg");
}

.add--fields span.img-common.train {
  background-image: url("img/Train.svg");
}

.add--fields span.img-common.excavator {
  background-image: url("img/Excavator.svg");
}

span.img_common_line.CAR {
  background-image: url("img/Car_line.svg");
}

span.img_common_line.TRUCK {
  background-image: url("img/Truck_line.svg");
}

span.img_common_line.BUS {
  background-image: url("img/Bus_line.svg");
}

span.img_common_line.MOTORBIKE {
  background-image: url("img/Motorbike_line.svg");
}

span.img_common_line.TRAILER {
  background-image: url("img/Trailer_line.svg");
}

span.img_common_line.TRACTOR {
  background-image: url("img/Tractor_line.svg");
}

span.img_common_line.BOAT {
  background-image: url("img/Boat_line.svg");
}

span.img_common_line.FORKLIFT {
  background-image: url("img/Forklift_line.svg");
}

span.img_common_line.TRAIN {
  background-image: url("img/Train_line.svg");
}

span.img_common_line.EXCAVATOR {
  background-image: url("img/Excavator_line.svg");
}

.img_common_line {
  height: 25px;
  width: 25px;
  cursor: pointer;
  position: relative;
  display: block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 25px;
  opacity: 1;
}

/* CHECKED STYLES */
.add--fields [type="radio"]:checked+.img-common.car {
  background-image: url("img/Carhover.svg");
}

.add--fields [type="radio"]:checked+.img-common.truck {
  background-image: url("img/truckhover.svg");
}

.add--fields [type="radio"]:checked+.img-common.bus {
  background-image: url("img/Bushover.svg");
}

.add--fields [type="radio"]:checked+.img-common.motorbike {
  background-image: url("img/Motorbikehover.svg");
}

.add--fields [type="radio"]:checked+.img-common.trailer {
  background-image: url("img/Trailerhover.svg");
}

.add--fields [type="radio"]:checked+.img-common.tractor {
  background-image: url("img/Tractorhover.svg");
}

.add--fields [type="radio"]:checked+.img-common.boat {
  background-image: url("img/Boathover.svg");
}

.add--fields [type="radio"]:checked+.img-common.forklift {
  background-image: url("img/Forklifthover.svg");
}

.add--fields [type="radio"]:checked+.img-common.train {
  background-image: url("img/Trainhover.svg");
}

.add--fields [type="radio"]:checked+.img-common.excavator {
  background-image: url("img/Excavatorhover.svg");
}

img.user-icon.logout {
  cursor: pointer;
  height: 20px;
  width: 20px;
}

.add--fields .form-check-input {
  float: right;
  font-size: 20px;
}

.add--fields .form-check-input:checked {
  background-color: #76ee59;
  border: #76ee59;
}

.popups-header {
  background: var(--blue);
  color: #FFFFFF;
}

.popups-header h2,
.popups-header .MuiButtonBase-root {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #FFFFFF;
}

.title-select,
.add--user .col-form-label,
.login_form .col-form-label {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--charcoal);
}

.login_form .col-form-label {
  padding: 0;
  margin-bottom: 10px;
  display: block;
}

.add--user .form-control {
  background: #FFFFFF;
  border-style: solid;
  border-color: #F5F5F5;
  border-radius: 3px 3px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  margin-bottom: 7px;
  font-size: 14px;
  line-height: 17px;
  color: var(--charcoal);
}

.add--user .form-control:focus {
  border-color: #333333 !important;
}

.user {
  font-weight: 600;
  font-size: 16px;
  position: relative;
  margin-Top: 8px;
  font-family: "Montserrat" !important;
  margin-right: 17px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 51px;
  height: 23px;
  margin-left: 20px;
  margin-top: -1px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #76ee59;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.react-confirm-alert-body {
  padding: 0 !important;
  font-family: 'Montserrat' !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center !important;
  color: #333333 !important;
  border-radius: 3px !important;
}

.react-confirm-alert-body button{
  border-radius: 3px !important;
}

.react-confirm-alert-body h1 {
  font-family: "Montserrat" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  background: #2c82d8;
  border-radius: 3px 3px 0px 0px;
  color: #ffffff;
  padding: 16px 20px;
  text-align: left;
  margin-bottom: 20px;
  height: 60px;
}

.react-confirm-alert {
  position: relative;
  max-width: 400px;
  width: 100%;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.25), 0px 24px 38px 3px rgba(0, 0, 0, 0.25), 0px 9px 46px 8px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  overflow: hidden;
}

.react-confirm-alert-button-group>button:hover svg,
.react-confirm-alert-button-group>button:hover svg path,
.popups-header .MuiButtonBase-root:hover svg,
.popups-header .MuiButtonBase-root:hover path {
  fill: #333333 !important;
}

button.no-confirm {
  position: absolute;
  top: 15px;
  right: 0;
  background: transparent !important;
  font-size: 22px !important;
  padding: 0 10px !important;
  color: #fff !important;
  font-weight: 600;
}

.react-confirm-alert-button-group {
  padding: 20px !important;
  margin-top: 0 !important;
}

.yes-confirm {
  font-style: normal;
  font-weight: 600;
  font-size: 14px !important;
  line-height: 17px;
  text-align: center;
  background: #2C82D8 !important;
  border-radius: 3px !important;
  width: 100% !important;
  margin-right: 0 !important;
  text-transform: uppercase !important;
}

.MuiSwitch-switchBase.checked {
  color: red !important;
}

.invalid-feedback {
  font-size: 12px !important;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  margin: .25rem 0rem;
}

.MuiCollapse-root.MuiCollapse-horizontal.MuiCollapse-entered {
  position: relative;
}

.MuiCollapse-root.MuiCollapse-horizontal.MuiCollapse-entered [aria-label="Clear search"]/* .MuiInputAdornment-root.MuiInputAdornment-positionEnd */{
  display: none;
}

.MuiInputAdornment-root.MuiInputAdornment-positionStart.MuiInputAdornment-standard {
  position: absolute;
  right: 0px;
}

/* Latest changes by Jigar  */
.datem .MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 100%;
}

.MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedStart {
  width: 300px;
  height: 30px;
  padding: 0px 10px;
  font-size: 16px;
  border: 1px solid #F0F0F0;
  border-radius: 4px;
  font-weight: 500;
  line-height: 17.07px;
  font-family: "Montserrat" !important;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
}

.MuiSwitch-root.MuiSwitch-sizeMedium .MuiSwitch-track {
  border-radius: 100px !important;
  background-color: rgba(0, 0, 0, 0.4) !important;
  width: 45px !important;
}

.MuiButtonBase-root.MuiSwitch-switchBase.MuiSwitch-colorPrimary.PrivateSwitchBase-root.Mui-checked+.MuiSwitch-track {
  background-color: rgba(118, 238, 89, 1) !important;
  opacity: 1 !important;
  border-radius: 100px !important;
  border-radius: 100px !important;
  box-shadow: inset 0px 10px 19px -6px rgba(0, 0, 0, 0.1);
}

.MuiSwitch-root.MuiSwitch-sizeMedium {
  height: 23px !important;
  padding: 0px !important;
  width: 46px !important;
  margin-left: 0px;
}

.MuiButtonBase-root.MuiSwitch-switchBase.MuiSwitch-colorPrimary.PrivateSwitchBase-root {
  top: -7px !important;
  transform: translateX(-5px) !important;
  -webkit-transform: translateX(4px) !important;
  -moz-transform: translateX(-5px) !important;
  -ms-transform: translateX(-5px) !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.MuiSwitch-root.MuiSwitch-sizeMedium .MuiSwitch-thumb {
  width: 18px !important;
  height: 18px !important;
}

.MuiButtonBase-root.MuiSwitch-switchBase.MuiSwitch-colorPrimary.PrivateSwitchBase-root.Mui-checked {
  color: #fff !important;
  transform: translateX(23px) !important;
  padding-left: 0px;
  padding-right: 0px;
}

.MuiButtonBase-root.MuiSwitch-switchBase.MuiSwitch-colorPrimary.PrivateSwitchBase-root {
  transform: translateX(5px) !important;
}

.css-tqueyn-MuiButtonBase-root-MuiIconButton-root,
.MuiInputAdornment-root.MuiInputAdornment-positionStart.MuiInputAdornment-standard .MuiSvgIcon-fontSizeMedium {
  fill: #999999 !important;
  opacity: 1 !important;
  width: 18px;
}

.exampleone {
  background-color: #2196f3;
}

.maintaine_history span {
  background-color: rgba(51, 51, 51, 1);
  padding: 1px 10px;
  border-radius: 3px;
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  font-family: 'Montserrat';
  text-transform: uppercase;
  margin-right: 15px;
  height: 37px;
}

.maintaine_history h3 {
  margin: 0;
  font-size: 24px;
  font-weight: 600;
  text-transform: capitalize;
}

.entry_popup .MuiDialog-paper {
  width: 800px;
  max-width: 800px;
}

.entry_popup .MuiDialogContent-root {
  padding: 20px;
}

.entry_popup .add--fields .pb-2 {
  padding-bottom: 10px !important;
}

.MuiDialogContent-root .gap-2 {
  gap: 0.5rem !important;
}

.add--entry .col-form-label {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #333333;
}

.f-600 {
  font-weight: 600 !important;
}

.checkbox-comman {
  height: 20px;
  width: 20px;
}

.form_search {
  position: relative;
}

.form_search input.form-control:focus,
.form_search input.form-control:active,
.form_search .form-control:focus,
.form_search .form-control:active,
.form_search .react-datepicker-wrapper .form-control {
  color: #333333;
}

.form_search .form-control:focus,
.form_search .form-control:active,
.MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedStart:focus,
.MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedStart:active {
  border: 1px solid #333333;
}

.form_search .form-control {
  width: 300px;
  height: 30px;
  padding: 0px 10px;
  color: #999999;
  font-weight: 500;
  line-height: 17.07px;
  font-family: "Montserrat" !important;
  font-size: 16px;
  border: 1px solid #F0F0F0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.form_search svg {
  fill: #999999;
  width: 18px;
  height: 18px;
  position: absolute;
  right: 10px;
  z-index: 3;
  top: 6px;
  cursor: pointer;
  background: #fff;
  padding: 0px 0px 0px 5px;
  overflow: hidden;
}

.form_search svg:hover {
  fill: #333;
}

.input_number {
  position: relative;
}

.input_number .text_position {
  position: absolute;
  contain: '';
  right: 7px;
  top: 8px;
  font-size: 12px;
  color: #999999;
  background-color: #fff;
  padding-left: 5px;
}

.input_number input {
  padding-right: 30px;
}

.input_number input::-webkit-outer-spin-button,
.input_number input::-webkit-inner-spin-button,
.input_number input[type=number],
.add--fields .form-control[type=number]{
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield;
}

.check_box input[type=checkbox] {
  position: relative;
  border: 1px solid #000;
  border-radius: 4px;
  background: none;
  cursor: pointer;
  line-height: 0;
  margin: 0 .1em 0 0;
  outline: 0;
  padding: 0 !important;
  vertical-align: text-top;
  height: 20px;
  width: 20px;
  -webkit-appearance: none;
  opacity: 1;
}

.check_box input[type=checkbox]:hover {
  opacity: 1;
}

.check_box input[type=checkbox]:checked {
  background-color: #000;
  opacity: 1;
}

.check_box input[type=checkbox]:before {
  content: '';
  position: absolute;
  right: 43%;
  top: 50%;
  width: 6px;
  height: 10px;
  border: solid #FFF;
  border-width: 0 2px 2px 0;
  margin: -1px -1px 0 -1px;
  transform: rotate(45deg) translate(-50%, -50%);
  z-index: 2;
}

.check_box input[type=checkbox].validate-error {
  border: 2px solid #FF0000;
}

.text_danger {
  color: #FF0000;
  font-weight: 500;
  font-family: "Montserrat";
  font-size: 12px;
}

.css-zcbmsk-MuiButtonBase-root-MuiButton-root:hover,
.css-1sl46e0-MuiButtonBase-root-MuiButton-root:hover {
  background-color: #333333 !important;
}

.css-zcbmsk-MuiButtonBase-root-MuiButton-root,
.css-1sl46e0-MuiButtonBase-root-MuiButton-root {
  font-weight: 600;
  font-size: 14px;
  border-radius: 3px !important;
}

.configure-section thead.MuiTableHead-root .MuiTableRow-root {
  box-shadow: none;
}

.configure-section tbody.MuiTableBody-root {
  border-collapse: unset;
  border: 1px solid #f5f5f5;
  border-top: 0px;
}

.MuiBox-root.css-sq9qdz,
.setting_page>.MuiPaper-root,
.configure_pag>.MuiPaper-root,
.configure_page>.MuiPaper-root {
  padding-left: 0px;
  padding-right: 0px;
}

.Mui-TableHeadCell-Content-Actions {
  display: none;
}

.css-yu8nt0-MuiTableBody-root tr:nth-of-type(odd) {
  background-color: #F5F5F5 !important;
}

.edit-btn:hover svg,
.edit-btn:hover span,
.delete-btn:hover svg,
.delete-btn:hover span,
.edit-btn:hover path {
  fill: #333333 !important;
  color: #333333 !important;
}

.MuiButtonBase-root.menu_drop svg {
  fill: #333333 !important;
  width: 18px;
}

/* popup design */
.d-w-400 .MuiPaper-root {
  max-width: 450px;
  width: 100%;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.25), 0px 24px 38px 3px rgba(0, 0, 0, 0.25), 0px 9px 46px 8px rgba(0, 0, 0, 0.25);
}

.pagination_top,
.maintenance_history {
  position: relative;
}

.pagination_top .MuiTablePagination-root,
.maintenance_history .MuiTablePagination-root {
  position: absolute;
  right: -28px;
}

.pagination_top .MuiTablePagination-root {
  top: -65px;
}

.maintenance_history .MuiTablePagination-root {
  top: -121px !important;
}

.pagination_top .MuiToolbar-root,
.maintenance_history .MuiToolbar-root {
  overflow: inherit;
}

.pagination_top .MuiTablePagination-root .MuiTablePagination-selectLabel,
.maintenance_history .MuiTablePagination-root .MuiTablePagination-selectLabel {
  display: none;
}

.pagination_top .MuiSelect-select.MuiTablePagination-select,
.maintenance_history .MuiSelect-select.MuiTablePagination-select,
.MuiPaper-root.MuiMenu-paper .MuiList-root.MuiMenu-list li{
  font-size: 14px;
  font-weight: 500;
  padding: 4px 26px 4px 10px !important;
  color: #333333;
  font-family: "Montserrat" !important;
}

.pagination_top .MuiInputBase-root.MuiInputBase-colorPrimary svg {
  fill: #CCCCCC;
  width: 1.2em;
}

.MuiTablePagination-displayedRows {
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #333333;
  line-height: 17.07px;
  font-family: "Montserrat" !important;
}

.pagination_top .MuiTablePagination-actions .MuiButtonBase-root,
.maintenance_history .MuiTablePagination-actions .MuiButtonBase-root {
  background-color: #2C82D8;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  margin: 5px;
}

.pagination_top .MuiTablePagination-actions .MuiButtonBase-root:hover,
.maintenance_history .MuiTablePagination-actions .MuiButtonBase-root:hover {
  background-color: #333333;
}

.pagination_top .MuiTablePagination-actions .MuiButtonBase-root svg,
.maintenance_history .MuiTablePagination-actions .MuiButtonBase-root svg {
  fill: #fff !important;
  width: 24px;
  height: 24px;
}

.popup_gape .MuiDialogContent-root,
.popup_gape .MuiDialogActions-root {
  padding: 20px !important;
}

.MuiTableContainer-root .MuiTable-root .MuiTableBody-root tr:hover,
.MuiTableContainer-root .MuiTable-root .MuiTableBody-root tr:hover td {
  background-color: rgba(44, 130, 216, 0.26) !important;
  transition: all 0.5s;
}

.form-check {
  margin-bottom: 15px;
}

.form-check .form-check-input {
  width: 20px;
  height: 20px;
  margin-right: 9px;
  border-color: #333333;
  position: relative;
  top: -2px;
}

.form-check .form-check-input:checked[type=checkbox] {
  background-color: #333333;
  border-color: #333333;
}

.form-check .form-check-input:focus {
  box-shadow: none;
}

.form-check label.form-check-label,
.MuiTableContainer-root .MuiTableRow-root.MuiTableRow-head th.MuiTableCell-root .Mui-TableHeadCell-Content-Wrapper {
  color: #333333;
  font-weight: 500;
}

.form-check label.form-check-label,
.form_label {
  font-size: 14px;
  font-family: 'Montserrat';
  color: #333333;
  font-weight: 500;
  line-height: 17.07px;
}

.MuiDialog-root .MuiPaper-root button.MuiButtonBase-root svg:hover path {
  fill: var(--charcoal);
}
.Mui-TableHeadCell-Content-Labels:has(.Mui-active) > .Mui-TableHeadCell-Content-Wrapper { color: #2C82D8 !important;}

.notify-head svg{ 
  font-size: 18px;
  color: #999999;
}

.Mui-active + .Mui-TableHeadCell-Content-Labels {
  background-color: lightblue; /* Change 'lightblue' to your desired color */
}

.MuiDialog-root .MuiPaper-root button.MuiButtonBase-root svg:hover,
.react-confirm-alert-body .no-confirm svg:hover {
  transform: rotate(90deg);
  transition: transform .25s, opacity .25s;
  fill: var(--charcoal);
}

.react-confirm-alert .react-confirm-alert-body .react-confirm-alert-button-group {
  padding: 20px !important;
  display: flex;
  justify-content: center;
}

.add--user .MuiStack-root,
.configure-section .MuiFormControl-root .MuiInputAdornment-root.MuiInputAdornment-positionEnd{
  margin: 0;
  gap: 0.7rem;
}

.disabled_filed input {
  background-color: #F0F0F0 !important;
}

.disabled_filed {
  color: #999999 !important;
}

.disabled_filed [type="checkbox"] {
  background-color: #999999 !important;
  border: 1px solid #999999 !important;
}

.date_icon,
.clock_icon {
  position: relative;
}

.date_icon .icon-date {
  position: absolute;
  right: 10px;
  top: 8px;
}

.date_icon .react-datepicker-wrapper {
  width: 100%;
}

.clock_icon input[type="time"]::-webkit-calendar-picker-indicator {
  filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg) brightness(95%) contrast(10%);
  width: 15px;
  height: 15px;
  position: absolute;
  top: 4px;
  right: 2px;
}

.maintenance_history .MuiToolbar-root>.MuiBox-root {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.maintenance_history .MuiToolbar-root>.MuiBox-root>.MuiBox-root {
  display: none;
}

.add--fields.add--entry:before {
  position: absolute;
  content: '';
  width: 1px;
  height: 100%;
  background: #F0F0F0;
  left: 50%;
  transform: translateX(450%);
  top: 0;
}

.add--fields.add--entry .MuiDialogActions-root {
  padding: 15px 0px;
}

.overview_table .MuiTable-root,
.maintenance_history .MuiTable-root,
.configure_page .MuiTable-root {
  border-collapse: collapse;
}

.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.5) !important;
}

.configure_page .MuiToolbar-root>.MuiBox-root,
.setting_page .MuiToolbar-root>.MuiBox-root {
  flex-wrap: wrap;
  justify-content: start;
  gap: 0.5rem 0px !important;
}

.setting-section .form_search .react-datepicker-wrapper .form-control {
  width: 200px;
}

.setting-section .form_search [type="time"].form-control {
  width: 120px;
  font-size: 16px;
  font-weight: 500;
  color: #333;
  padding-right: 0 !important;
}

.setting-section .form_search [type="time"]::-webkit-calendar-picker-indicator {
  background: none;
}

.configure-section td.MuiTableCell-root::first-letter {
  text-transform: capitalize;
}

.add--fields .react-datepicker-wrapper {
  width: 100%;
}

.header-nav .user-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.header-nav .notify {
  width: 18.33px;
  height: 20px;
}

p.MuiTypography-root.MuiTypography-body1 {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-family: "Montserrat" !important;
  max-width: 100%;
}

.custom-loader {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 10px solid #0000;
  border-right-color: #2C82D8;
  position: relative;
  animation: s4 1s infinite linear;
}

.custom-loader:before,
.custom-loader:after {
  content: "";
  position: absolute;
  inset: -10px;
  border-radius: 50%;
  border: inherit;
  animation: inherit;
  animation-duration: 2s;
}

.custom-loader:after {
  animation-duration: 4s;
}

@keyframes s4 {
  100% {
    transform: rotate(1turn)
  }
}

.configure-section .MuiToolbar-root>.MuiBox-root {
  padding-bottom: 0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-bottom-color: #2C82D8 !important;
}

.react-datepicker__header {
  background-color: #2C82D8 !important;
}

.react-datepicker-time__header,
.react-datepicker-year-header,
.react-datepicker__current-month {
  color: #fff !important;
}

.react-datepicker__day-name {
  color: #fff !important;
}

.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before,
.react-datepicker__year-read-view--down-arrow {
  top: 13px !important;
}

.react-datepicker__navigation:hover ::before {
  border-color: #000 !important;
}

.css-1nmdiq5-menu {
  z-index: 1000 !important;
  position: relative !important;
  top: 0 !important;
}

.setting__page .MuiToolbar-root > .MuiBox-root, 
.configuration__page .MuiToolbar-root > .MuiBox-root,
.setting__page #home,
.overview_table .MuiToolbar-root > .MuiBox-root,
.popup_gape .MuiDialogActions-root{
  padding-top: 0 !important;
}

.configure-section .MuiPaper-root > .MuiToolbar-root{  
  min-height: auto;
  height: auto;
}
.swal2-container .swal2-popup .swal2-icon { 
  margin-top: 10px; 
  margin-bottom: 0px;
}
.swal2-container .swal2-popup button[type="button"] { 
  padding: 7px 15px !important; 
  height: 33px;
}
.deletebtn{
  color: #CCCCCC
}
.deletebtn:hover{
  color: #333333;
}




/* ------------------------------------------------- */
/* ----------------  responsive css ---------------- */
/* ------------------------------------------------- */
@media (max-width: 1600px) {
  .notification_page .form_search .form-control { width: 100%; }
  .setting-section .form_search .react-datepicker-wrapper .form-control { width: 150px; }
}

@media (max-width: 1307px) {
  .notification_page .ms_lg_auto { margin-left: inherit !important; }
}

@media (min-width: 993px) {
    .notification_page{ padding-top: 16px !important; }
    .overview_table .MuiToolbar-root > .MuiBox-root > div:first-child,
    .maintenance_history .MuiToolbar-root > .MuiBox-root{
      padding-top: 20px !important;
    }
}

@media (max-width: 1367px) {
  .form_search .form-control { width: 250px; }
  .popup_gape .MuiButtonBase-root svg,
  .react-confirm-alert .react-confirm-alert-body .react-confirm-alert-button-group svg { width: 20px; }
  .configure-section .MuiBox-root .add-new-field { margin-left: inherit; }
}

@media(max-width: 992px) {
  .MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedStart { width: 250px; }
  .form_search .form-control,
  .w-md-50{ width: 100%; }
  .configure-section .md-grid-cols-2{  
    display: grid !important;
    grid-template-columns: repeat(2,minmax(0,1fr));
  }
  .w-md-100,
  .overview_table .box-1 { width: 99%; }
  .maintenance_history .MuiBox-root .add-new-field { margin-left: auto; }
  .configure_page.configure-section .MuiPaper-root,
  .setting_page.configure-section .MuiPaper-root,
  .maintenance_history .MuiToolbar-root > .MuiBox-root { padding: 0 !important; }
  .pagination_top .MuiTablePagination-root,
  .maintenance_history .MuiTablePagination-root { right: -28px; top: -85px; }
  .maintenance_history .MuiTablePagination-root { top: -141px !important; }
}


@media (max-width: 767px) {
  .setting_page .MuiCollapse-root .MuiInputBase-input,
  .setting_page .MuiToolbar-root .MuiCollapse-wrapper .MuiCollapse-wrapperInner,
  .configure_page .MuiCollapse-root .MuiInputBase-input,
  .configure_page .MuiToolbar-root .MuiCollapse-wrapper .MuiCollapse-wrapperInner { width: 100%; }
  .setting_page .MuiCollapse-root .MuiFormControl-root,
  .configure_page .MuiCollapse-root .MuiFormControl-root { max-width: 100%; min-width: 100%; }
  .breadcrumb-item.active { font-size: 24px; }
  .maintaine_history span,
  .maintaine_history h3 { font-size: 18px;}
  .add--fields.add--entry:before { display: none; }

  /* Track */
  ::-webkit-scrollbar-track { box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1); border-radius: 10px; }
  ::-webkit-scrollbar-thumb { background: rgba(0, 0, 0, 0.1); border-radius: 10px; }
  ::-webkit-scrollbar-thumb:hover { background: rgba(0, 0, 0, 0.1); }
  body { font-family: "Montserrat", sans-serif; }
  .bg-primary { background-color: var(--blue) !important; }
  .main-listing { width: calc(100% - 61px); }

  @keyframes dropdown-animate {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }

    0% {
      opacity: 0;
    }
  }

  .top-header .logo h2 { font-family: "Montserrat"; font-style: normal; font-weight: 700; font-size: 24px; line-height: 29px; color: var(--charcoal); padding: 16px 10px; margin-bottom: 0;}
  .top-bar { background: var(--white); mix-blend-mode: normal; box-shadow: 0px 1px 1px rgba(51, 51, 51, 0.1); }
  .top-bar .logo img { height: 40px; width: 204px; }
  .main-content { background: var(--Gray94); }
  .sidebar { background: var(--charcoal); }
  .nav-pills .nav-link { padding: 20px !important; border-radius: 0; transition: all 0.2s;}
  .nav-pills .nav-link:hover,
  .nav-pills .nav-link:focus,
  .nav-pills .nav-link.active { background: var(--blue); }
  .notification-counter { background-color: var(--blue) !important; position: absolute; font-size: 8px; line-height: 14px; text-align: center; right: 0px; top: 3px; width: 20px; font-weight: 500; padding: 0em .30em; height: 15px; }
  .dropdown-menu-arrow::before { content: ""; width: 13px; height: 13px; background: var(--white); position: absolute; top: -7px; right: 20px; transform: rotate(45deg); border-top: 1px solid #eaedf1; border-left: 1px solid #eaedf1; }
  .dropdown-menu { border-radius: 4px; padding: 10px 0; animation-name: dropdown-animate; animation-duration: 0.2s; animation-fill-mode: both; border: 0; box-shadow: 0 5px 30px 0 rgba(82, 63, 105, 0.2); }
  .header-nav .notifications { inset: 8px 0 auto auto !important; }
  .header-nav .dropdown-menu .dropdown-header { text-align: center; font-size: 15px; padding: 10px 25px;}
  .configure_page tr td:first-child { text-align: center; }
  .header-nav .notifications .notification-item h4 { font-size: 16px; font-weight: 600; margin-bottom: 5px;}
  .header-nav .notifications .notification-item p { margin-bottom: 0; font-size: 13px; margin-bottom: 3px; color: #919191; }
  .header-nav .notifications .notification-item { display: flex; align-items: center; padding: 5px 10px; transition: 0.3s;}
  .nav-profile .user-name { font-style: normal; font-weight: 500; font-size: 14px; line-height: 17px; color: var(--Gray60); font-family: "Montserrat" !important;}
  .nav-profile .user-tagline { font-style: normal; font-weight: 500; font-size: 10px; line-height: 12px; text-align: right; color: var(--Gray60); float: right; font-family: "Montserrat" !important; text-transform: capitalize;}
  .breadcrumb-item.active { font-style: normal; font-weight: 500; font-size: 30px; line-height: 37px; color: var(--charcoal);}
  .tab-white { background-color: var(--white); padding: 0px;}
  .configuration-tabs {border: 0; }
  .configuration-tabs .nav-link { padding: 13px 20px; font-style: normal; font-weight: 500; font-size: 14px; line-height: 17px; border: 0; color: var(--charcoal); }
  .add-new-field { background-color: var(--blue) !important; font-family: "Montserrat" !important; font-style: normal !important; font-weight: 600 !important; font-size: 14px !important; line-height: 17px !important; box-shadow: none !important; padding: 7px 10px !important; }
  .add-new-field:hover,
  .add-new-field:active { background-color: var(--charcoal) !important; }
  .configure-section .MuiPaper-root { box-shadow: none !important; padding: 0px 15px 20px; }
  .configure_page.configure-section .MuiPaper-root,
  .setting_page.configure-section .MuiPaper-root { padding-bottom: 8px; }
  .configure-section .MuiBox-root .add-new-field { margin-left: auto; }
  .configure-section .MuiInputBase-root .MuiSelect-select { background: #ffffff; border: 1px solid #f0f0f0; border-radius: 3px; }
  .configure-section .MuiInputBase-root::before,
  .configure-section .MuiInputBase-root::after { display: none !important;}
  .edit-btn:hover,
  .delete-btn:hover,
  .edit-btn:focus,
  .delete-btn:focus { background-color: transparent !important; }
  .edit-btn span,
  .delete-btn span { color: var(--blue) !important; font-family: "Montserrat"; font-style: normal; font-weight: 500; font-size: 12px; line-height: 15px; }
  .edit-btn .MuiSvgIcon-root,
  .delete-btn .MuiSvgIcon-root { width: 15px; height: 15px; color: #999999 !important; margin-right: 1px; }
  .configure-section .MuiTableSortLabel-icon { opacity: 1 !important; }
  .configure-section .Mui-TableHeadCell-Content-Labels { display: flex; flex-direction: row-reverse; font-family: "Montserrat" !important; font-style: normal; font-weight: 500; font-size: 14px; line-height: 17.07px; }
  .configure-section .MuiBadge-root { color: #999999; }
  .configure-section .MuiTableContainer-root .MuiTableRow-root.MuiTableRow-head th { border: 0; color: #333333; font-weight: 500; padding: 0.7rem 0.5rem; vertical-align: middle; }
  .configure-section .MuiTableContainer-root .MuiTableRow-root.MuiTableRow-head th:nth-child(1) { opacity: 0; }
  .configure-section.setting-section .MuiTableContainer-root .MuiTableRow-root.MuiTableRow-head th:nth-child(1) { opacity: 1; }
  .configure-section td.MuiTableCell-root { font-family: "Montserrat" !important; font-style: normal !important; font-weight: 500 !important; font-size: 12px !important; line-height: 15px !important; color: var(--charcoal) !important; padding: 0.2rem 0.5rem; height: 46px; }
  span.active { background: #76ee59; border-radius: 50px; width: 20px; height: 20px; display: block; margin: 0 auto; }
  span.inactive { background: red; border-radius: 50px; width: 20px; height: 20px; display: block; margin: 0 auto; }
  .popup-header { background: var(--blue); color: #ffffff; }
  .popup-header h2,
  .popup-header .MuiButtonBase-root { font-family: "Montserrat"; font-style: normal; font-weight: 600; font-size: 18px; line-height: 22px; color: #ffffff; }
  .title-select,
  .add--fields .col-form-label { font-family: "Montserrat"; font-style: normal; font-weight: 600; font-size: 16px; line-height: 20px; color: var(--charcoal); }
  .add--fields .form-control { background: #ffffff; border-style: solid; border-color: #f5f5f5; border-radius: 3px 3px; font-family: "Montserrat"; font-style: normal; font-weight: 500; font-size: 14px; line-height: 17px; color: var(--charcoal); }
  .form-control:focus { box-shadow: none; border-color: var(--charcoal); }
  .select-dropdown { background-image: url(img/dropdown-icon.svg) !important; background-repeat: no-repeat !important; background-position: center right 10px !important; }
  .select-dropdown .odd { background-color: #f5f5f5 !important; padding: 10px;}
  .select-dropdown .even { background-color: var(--white) !important; padding: 10px;}
  .add--fields .MuiStack-root { gap: 0.5rem; }
  .add--fields [type="radio"] { position: absolute; opacity: 0; height: 30px; width: 50px; cursor: pointer;}
  .radio-common { position: relative; cursor: pointer;}

  /* IMAGE STYLES */
  .add--fields [type="radio"]+.img-common { cursor: pointer; height: 30px; width: 50px; display: block; background-position: center; }
  .add--fields span.img-common { background-repeat: no-repeat; cursor: pointer; }
  span.img_common { height: 25px; width: 25px; cursor: pointer; position: relative; display: block; background-repeat: no-repeat; background-position: center; background-size: 25px; opacity: 1;}
  span.img_common.CAR { background-image: url("img/Carhover.svg"); }
  span.img_common.TRUCK { background-image: url("img/truckhover.svg");}
  span.img_common.BUS { background-image: url("img/Bushover.svg");}
  span.img_common.MOTORBIKE { background-image: url("img/Motorbikehover.svg");}
  span.img_common.TRAILER { background-image: url("img/Trailerhover.svg");}
  span.img_common.TRACTOR { background-image: url("img/Tractorhover.svg");}
  span.img_common.BOAT {background-image: url("img/Boathover.svg");}
  span.img_common.FORKLIFT {background-image: url("img/Forklifthover.svg");}
  span.img_common.TRAIN {background-image: url("img/Trainhover.svg");}
  span.img_common.EXCAVATOR {background-image: url("img/Excavatorhover.svg");}
  .add--fields span.img-common.car { background-image: url("img/Car.svg");}
  .add--fields span.img-common.truck { background-image: url("img/truck.svg");}
  .add--fields span.img-common.bus { background-image: url("img/Bus.svg");}
  .add--fields span.img-common.motorbike { background-image: url("img/Motorbike.svg");}
  .add--fields span.img-common.trailer {background-image: url("img/Trailer.svg");}
  .add--fields span.img-common.tractor {background-image: url("img/Tractor.svg");}
  .add--fields span.img-common.boat {background-image: url("img/Boat.svg");}
  .add--fields span.img-common.forklift {background-image: url("img/Forklift.svg");}
  .add--fields span.img-common.train {background-image: url("img/Train.svg");}
  .add--fields span.img-common.excavator {background-image: url("img/Excavator.svg");}
  span.img_common_line.CAR {background-image: url("img/Car_line.svg");}
  span.img_common_line.TRUCK {background-image: url("img/Truck_line.svg");}
  span.img_common_line.BUS {background-image: url("img/Bus_line.svg");}
  span.img_common_line.MOTORBIKE {background-image: url("img/Motorbike_line.svg");}
  span.img_common_line.TRAILER {background-image: url("img/Trailer_line.svg");}
  span.img_common_line.TRACTOR {background-image: url("img/Tractor_line.svg");}
  span.img_common_line.BOAT {background-image: url("img/Boat_line.svg");}
  span.img_common_line.FORKLIFT {background-image: url("img/Forklift_line.svg");}
  span.img_common_line.TRAIN {background-image: url("img/Train_line.svg");}
  span.img_common_line.EXCAVATOR {background-image: url("img/Excavator_line.svg");}
  .img_common_line {height: 25px;width: 25px;cursor: pointer;position: relative;display: block;background-repeat: no-repeat;background-position: center;background-size: 25px;opacity: 1;}


  /* CHECKED STYLES */
  .add--fields [type="radio"]:checked+.img-common.car {background-image: url("img/Carhover.svg");}
  .add--fields [type="radio"]:checked+.img-common.truck {background-image: url("img/truckhover.svg");}
  .add--fields [type="radio"]:checked+.img-common.bus {background-image: url("img/Bushover.svg");}
  .add--fields [type="radio"]:checked+.img-common.motorbike {background-image: url("img/Motorbikehover.svg");}
  .add--fields [type="radio"]:checked+.img-common.trailer { background-image: url("img/Trailerhover.svg");}
  .add--fields [type="radio"]:checked+.img-common.tractor { background-image: url("img/Tractorhover.svg");}
  .add--fields [type="radio"]:checked+.img-common.boat { background-image: url("img/Boathover.svg");}
  .add--fields [type="radio"]:checked+.img-common.forklift { background-image: url("img/Forklifthover.svg");}
  .add--fields [type="radio"]:checked+.img-common.train { background-image: url("img/Trainhover.svg");}
  .add--fields [type="radio"]:checked+.img-common.excavator { background-image: url("img/Excavatorhover.svg"); }
  img.user-icon.logout { cursor: pointer; height: 20px; width: 20px;}
  .add--fields .form-check-input { float: right; font-size: 20px;}
  .add--fields .form-check-input:checked { background-color: #76ee59; border: #76ee59;}
  .popups-header { background: var(--blue); color: #FFFFFF; }
  .popups-header h2,
  .popups-header .MuiButtonBase-root { font-family: 'Montserrat'; font-style: normal; font-weight: 600; font-size: 18px; line-height: 22px; color: #FFFFFF; }
  .title-select,
  .add--user .col-form-label { font-family: 'Montserrat'; font-style: normal; font-weight: 600; font-size: 16px; line-height: 20px; color: var(--charcoal);}
  .add--user .form-control { background: #FFFFFF; border-style: solid; border-color: #F5F5F5; border-radius: 3px 3px; font-family: 'Montserrat'; font-style: normal; font-weight: 500; margin-bottom: 7px; font-size: 14px; line-height: 17px; color: var(--charcoal);}
  .add--user .form-control:focus { border-color: #333333 !important;}
  .user { font-weight: 600; font-size: 14px; position: relative; margin-Top: 8px; font-family: "Montserrat" !important;}
  .switch { position: relative; display: inline-block; width: 51px; height: 23px; margin-left: 20px; margin-top: -1px;}
  .switch input {  opacity: 0;  width: 0;  height: 0;  }
  .slider {  position: absolute;  cursor: pointer;  top: 0;  left: 0;  right: 0;  bottom: 0;  background-color: #ccc;  -webkit-transition: 0.4s;  transition: 0.4s;  }
  .slider:before {  position: absolute;  content: "";  height: 20px;  width: 20px;  left: 3px;  bottom: 2px;  background-color: white;  -webkit-transition: 0.4s;  transition: 0.4s;  }
  input:checked+.slider { background-color: #76ee59;}
  input:focus+.slider { box-shadow: 0 0 1px #2196f3;}
  input:checked+.slider:before { -webkit-transform: translateX(26px); -ms-transform: translateX(26px); transform: translateX(26px);}

  /* Rounded sliders */
  .slider.round {  border-radius: 34px;}
  .slider.round:before { border-radius: 50%;}
  /* label { color: red; } */
  .react-confirm-alert-body { padding: 0 !important; font-family: 'Montserrat' !important; font-style: normal; font-weight: 600; font-size: 16px; line-height: 20px; text-align: center !important; color: #333333 !important; }
  .react-confirm-alert-body h1 { font-family: "Montserrat" !important; font-style: normal; font-weight: 600; font-size: 18px; line-height: 22px; background: #2c82d8; border-radius: 3px 3px 0px 0px; color: #ffffff; padding: 16px 20px; text-align: left; margin-bottom: 20px; }
  .react-confirm-alert { position: relative; max-width: 400px; width: 100%; box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.25), 0px 24px 38px 3px rgba(0, 0, 0, 0.25), 0px 9px 46px 8px rgba(0, 0, 0, 0.25); border-radius: 4px; overflow: hidden;}
  .react-confirm-alert-button-group>button:hover svg,
  .react-confirm-alert-button-group>button:hover svg path,
  .popups-header .MuiButtonBase-root:hover svg,
  .popups-header .MuiButtonBase-root:hover path { fill: #333333 !important; }
  button.no-confirm { position: absolute; top: 15px; right: 0; background: transparent !important; font-size: 22px !important; padding: 0 10px !important; color: #fff !important; font-weight: 600; }
  .react-confirm-alert-button-group { padding: 20px !important; margin-top: 0 !important;}
  .yes-confirm { font-style: normal; font-weight: 600; font-size: 14px !important; line-height: 17px; text-align: center; background: #2C82D8 !important; border-radius: 3px !important; width: 100% !important; margin-right: 0 !important; }
  .MuiSwitch-switchBase.checked {  color: red !important; }
  .invalid-feedback { font-size: 12px !important; font-family: 'Montserrat'; font-style: normal; font-weight: 600; margin-bottom: 7px; }
  .MuiCollapse-root.MuiCollapse-horizontal.MuiCollapse-entered { position: relative;}
  .MuiCollapse-root.MuiCollapse-horizontal.MuiCollapse-entered [aria-label="Clear search"] { display: none;}
  .MuiInputAdornment-root.MuiInputAdornment-positionStart.MuiInputAdornment-standard { position: absolute; right: 0px;}
  .MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedStart { width: 300px; height: 30px; padding: 0px 10px; font-size: 14px; border: 1px solid #F0F0F0; border-radius: 4px; font-weight: 500; line-height: 17.07px; font-family: "Montserrat" !important; color: #333333; }
  .MuiSwitch-root.MuiSwitch-sizeMedium .MuiSwitch-track { border-radius: 100px !important; background-color: rgba(0, 0, 0, 0.4) !important; width: 45px !important;}
  .MuiButtonBase-root.MuiSwitch-switchBase.MuiSwitch-colorPrimary.PrivateSwitchBase-root.Mui-checked+.MuiSwitch-track { background-color: rgba(118, 238, 89, 1) !important; opacity: 1 !important; border-radius: 100px !important; border-radius: 100px !important; box-shadow: inset 0px 10px 19px -6px rgba(0, 0, 0, 0.1);}
  .MuiSwitch-root.MuiSwitch-sizeMedium { height: 23px !important; padding: 0px !important; width: 45px !important; margin-left: 10px;}
  .MuiButtonBase-root.MuiSwitch-switchBase.MuiSwitch-colorPrimary.PrivateSwitchBase-root { top: -7px !important; transform: translateX(-5px) !important; -webkit-transform: translateX(4px) !important; -moz-transform: translateX(-5px) !important; -ms-transform: translateX(-5px) !important; padding-left: 0px !important; padding-right: 0px !important;}
  .MuiSwitch-root.MuiSwitch-sizeMedium .MuiSwitch-thumb { width: 18px !important; height: 18px !important;}
  .MuiButtonBase-root.MuiSwitch-switchBase.MuiSwitch-colorPrimary.PrivateSwitchBase-root.Mui-checked { color: #fff !important; transform: translateX(23px) !important; padding-left: 0px; padding-right: 0px;}
  .MuiButtonBase-root.MuiSwitch-switchBase.MuiSwitch-colorPrimary.PrivateSwitchBase-root { transform: translateX(5px) !important;}
  .css-tqueyn-MuiButtonBase-root-MuiIconButton-root,
  .MuiInputAdornment-root.MuiInputAdornment-positionStart.MuiInputAdornment-standard .MuiSvgIcon-fontSizeMedium { fill: #999999 !important; opacity: 1 !important; width: 18px;}
  .exampleone { background-color: #2196f3;}
  .maintaine_history span { background-color: rgba(51, 51, 51, 1); padding: 1px 10px; border-radius: 3px; color: #fff; font-size: 24px; font-weight: 500; font-family: 'Montserrat'; text-transform: uppercase; margin-right: 15px;}
  .maintaine_history h3 { margin: 0; font-size: 24px; font-weight: 600; text-transform: capitalize;}
  .entry_popup .MuiDialog-paper { width: 800px; max-width: 800px;}
  .entry_popup .MuiDialogContent-root { padding: 15px;}
  .add--entry .col-form-label { font-family: Montserrat; font-size: 14px; font-weight: 500; line-height: 17px; letter-spacing: 0em; text-align: left; color: #333333;}
  .f-600 {font-weight: 600 !important;}
  .checkbox-comman {height: 20px;width: 20px;}
  .form_search {position: relative;}
  .form_search input.form-control:focus,
  .form_search input.form-control:active,
  .form_search .form-control:focus,
  .form_search .form-control:active,
  .form_search .react-datepicker-wrapper .form-control {color: #333333;}
  .form_search .form-control:focus,
  .form_search .form-control:active,
  .MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedStart:focus,
  .MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedStart:active { border: 1px solid #333333; }
  .form_search .form-control { width: 300px; height: 31px; padding: 0px 10px; color: #999999; font-weight: 500; line-height: 17.07px; font-family: "Montserrat" !important; font-size: 14px; border: 1px solid #F0F0F0; }
  .form_search svg { transform: rotate(-0.72turn); fill: #999999; width: 16px; height: 16px; position: absolute; right: 8px; z-index: 3; top: 6px; cursor: pointer; }
  .form_search svg:hover { fill: #333; }
  .input_number { position: relative; }
  .input_number .text_position { position: absolute; contain: ''; right: 7px; top: 8px; font-size: 12px; color: #999999; }
  .input_number input { padding-right: 30px; }
  .input_number input::-webkit-outer-spin-button,
  .input_number input::-webkit-inner-spin-button { -webkit-appearance: none; margin: 0; }
  .check_box input[type=checkbox] { position: relative; border: 1px solid #000; border-radius: 4px; background: none; cursor: pointer; line-height: 0; margin: 0 .1em 0 0; outline: 0; padding: 0 !important; vertical-align: text-top; height: 20px; width: 20px; -webkit-appearance: none; opacity: 1; }
  .check_box input[type=checkbox]:hover { opacity: 1;}
  .check_box input[type=checkbox]:checked { background-color: #000; opacity: 1;}
  .check_box input[type=checkbox]:before { content: ''; position: absolute; right: 43%; top: 50%; width: 6px; height: 10px; border: solid #FFF; border-width: 0 2px 2px 0; margin: -1px -1px 0 -1px; transform: rotate(45deg) translate(-50%, -50%); z-index: 2;}
  .check_box input[type=checkbox].validate-error {border: 2px solid #FF0000; }
  .text_danger {color: #FF0000;font-weight: 500;font-family: "Montserrat";font-size: 12px; }
  .css-zcbmsk-MuiButtonBase-root-MuiButton-root:hover,
  .css-1sl46e0-MuiButtonBase-root-MuiButton-root:hover {background-color: #333333 !important; }
  .css-zcbmsk-MuiButtonBase-root-MuiButton-root,
  .css-1sl46e0-MuiButtonBase-root-MuiButton-root {font-weight: 600;font-size: 14px; }
  .configure-section thead.MuiTableHead-root .MuiTableRow-root {box-shadow: none; }
  .configure-section tbody.MuiTableBody-root { border-collapse: unset; border: 1px solid #f5f5f5; border-top: 0px;}
  .MuiBox-root.css-sq9qdz,
  .setting_page>.MuiPaper-root,
  .configure_pag>.MuiPaper-root,
  .configure_page>.MuiPaper-root { padding-left: 0px; padding-right: 0px;}
  .Mui-TableHeadCell-Content-Actions { display: none;}
  .css-yu8nt0-MuiTableBody-root tr:nth-of-type(odd) { background-color: #F5F5F5 !important;}
  .edit-btn:hover svg,
  .edit-btn:hover span,
  .delete-btn:hover svg,
  .delete-btn:hover span,
  .edit-btn:hover path { fill: #333333 !important; color: #333333 !important;}
  .MuiButtonBase-root.menu_drop svg { fill: #333333 !important; width: 18px;}

  /* popup design */
  .d-w-400 .MuiPaper-root { max-width: 450px; width: 100%; box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.25), 0px 24px 38px 3px rgba(0, 0, 0, 0.25), 0px 9px 46px 8px rgba(0, 0, 0, 0.25);}
  .pagination_top,
  .maintenance_history { position: relative; }
  .pagination_top .MuiTablePagination-root,
  .maintenance_history .MuiTablePagination-root { position: absolute; right: -27px; }
  .pagination_top .MuiTablePagination-root { top: -84px; right: -28px; }
  .maintenance_history .MuiTablePagination-root { top: -140px !important; }
  .pagination_top .MuiToolbar-root,
  .maintenance_history .MuiToolbar-root { overflow: inherit;}
  .pagination_top .MuiTablePagination-root .MuiTablePagination-selectLabel,
  .maintenance_history .MuiTablePagination-root .MuiTablePagination-selectLabel { display: none;}
  .pagination_top .MuiSelect-select.MuiTablePagination-select,
  .maintenance_history .MuiSelect-select.MuiTablePagination-select { font-size: 14px; font-weight: 500; padding: 5px 26px 5px 10px !important; color: #333333; font-family: "Montserrat" !important;}
  .pagination_top .MuiInputBase-root.MuiInputBase-colorPrimary svg { fill: #CCCCCC; width: 1.2em;}
  .MuiTablePagination-displayedRows { font-weight: 500 !important; font-size: 14px !important; color: #333333; line-height: 17.07px; font-family: "Montserrat" !important;}
  .pagination_top .MuiTablePagination-actions .MuiButtonBase-root,
  .maintenance_history .MuiTablePagination-actions .MuiButtonBase-root { background-color: #2C82D8; width: 30px; height: 30px; border-radius: 4px; margin: 5px;}
  .pagination_top .MuiTablePagination-actions .MuiButtonBase-root:hover,
  .maintenance_history .MuiTablePagination-actions .MuiButtonBase-root:hover { background-color: #333333;}
  .pagination_top .MuiTablePagination-actions .MuiButtonBase-root svg,
  .maintenance_history .MuiTablePagination-actions .MuiButtonBase-root svg { fill: #fff !important; width: 24px; height: 24px;}
  .popup_gape .MuiDialogContent-root,
  .popup_gape .MuiDialogActions-root {padding: 15px !important;}
  .popup_gape .MuiDialogActions-root {padding-top: 0px !important;}
  .MuiTableContainer-root .MuiTable-root .MuiTableBody-root tr:hover,
  .MuiTableContainer-root .MuiTable-root .MuiTableBody-root tr:hover td { background-color: rgba(44, 130, 216, 0.26) !important; transition: all 0.5s;}
  .form-check { margin-bottom: 15px;}
  .flex_wrap_420 { flex-wrap: wrap;}
  .react-confirm-alert,
  .react-confirm-alert-body { max-width: 290px;}
  .box_wrap_info .bg-white,
  .swal2-container .swal2-popup { width: auto !important;}
}

@media (max-width: 375px) {

  .setting_page .MuiToolbar-root .MuiBox-root>.MuiButtonBase-root,
  .configure_page .MuiToolbar-root .MuiBox-root>.MuiButtonBase-root,
  .overview_table .box-2 .MuiButtonBase-root,
  .notification_page .line { max-width: 100% !important; width: 100% !important;}
  .setting_page .MuiToolbar-root>.MuiBox-root { padding-top: 0; gap: 13px;}
  .top-bar .logo img { height: 20px; margin-right: 9px; border-color: #333333; position: relative; top: -2px;}
  .form-check .form-check-input:checked[type=checkbox] { background-color: #333333; border-color: #333333;}
  .form-check .form-check-input:focus { box-shadow: none;}
  .form-check label.form-check-label,
  .MuiTableContainer-root .MuiTableRow-root.MuiTableRow-head th.MuiTableCell-root .Mui-TableHeadCell-Content-Wrapper { color: #333333; font-weight: 500;}
  .form-check label.form-check-label,
  .form_label { font-size: 14px; font-family: 'Montserrat'; color: #333333; font-weight: 500;}
  .setting_page tr th:nth-child(6) .Mui-TableHeadCell-Content-Wrapper,
  .setting_page tr th:nth-child(6) svg,
  .configure_page tr th:nth-child(3) .Mui-TableHeadCell-Content-Wrapper,
  .configure_page tr th:nth-child(3) svg,
  .overview_table table tr.MuiTableRow-root th:nth-child(2) .Mui-TableHeadCell-Content-Wrapper,
  .maintenance_history table tr.MuiTableRow-root th:nth-child(1) .Mui-TableHeadCell-Content-Wrapper,
  .overview_table table tr.MuiTableRow-root th:nth-child(2) svg { color: #2C82D8 !important; fill: #2C82D8 !important;}
  .MuiDialog-root .MuiPaper-root button.MuiButtonBase-root svg:hover path { fill: var(--charcoal);}
  .MuiDialog-root .MuiPaper-root button.MuiButtonBase-root svg:hover,
  .react-confirm-alert-body .no-confirm svg:hover { transform: rotate(90deg); transition: transform .25s, opacity .25s; fill: var(--charcoal);}
  .react-confirm-alert .react-confirm-alert-body .react-confirm-alert-button-group { padding: 15px !important; display: flex; justify-content: center;}
  .add--user .MuiStack-root { margin: 0; gap: 0.7rem;}
  .disabled_filed input { background-color: #F0F0F0 !important;}
  .disabled_filed { color: #999999 !important;}
  .disabled_filed [type="checkbox"] { background-color: #999999 !important; border: 1px solid #999999 !important;}
  .date_icon,
  .clock_icon { position: relative;}
  .date_icon .icon-date { position: absolute; right: 10px; top: 8px;}
  .date_icon .react-datepicker-wrapper { width: 100%; }
  .clock_icon input[type="time"]::-webkit-calendar-picker-indicator { filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg) brightness(95%) contrast(10%); width: 15px; height: 15px; position: absolute; top: 4px; right: 2px; }
  .maintenance_history .MuiToolbar-root>.MuiBox-root { display: flex; align-items: center; flex-wrap: wrap; justify-content: space-between; }
  .maintenance_history .MuiToolbar-root>.MuiBox-root>.MuiBox-root { display: none; }
  .add--fields.add--entry:before { position: absolute; content: ''; width: 1px; height: 100%; background: #F0F0F0; left: 50%; transform: translateX(450%); top: 0; }
  .add--fields.add--entry .MuiDialogActions-root { padding: 15px 0px; }
  .overview_table .MuiTable-root,
  .maintenance_history .MuiTable-root,
  .configure_page .MuiTable-root { border-collapse: collapse; }
  .react-confirm-alert-overlay { background: rgba(0, 0, 0, 0.5) !important; }
  .configure_page .MuiToolbar-root>.MuiBox-root,
  .setting_page .MuiToolbar-root>.MuiBox-root { flex-wrap: wrap; justify-content: start; gap: 0.5rem !important; }
  .setting-section .form_search .react-datepicker-wrapper .form-control { width: 200px; }
  .setting-section .form_search [type="time"].form-control { width: 120px; font-size: 14px; font-weight: 500; color: #333; }
  .setting-section .form_search [type="time"]::-webkit-calendar-picker-indicator { background: none; }
  .setting-section .form_search [type="time"] { background-image: url(img/dropdown-icon.svg) !important; background-repeat: no-repeat !important; background-position: center right 10px !important; }
  .configure-section td.MuiTableCell-root::first-letter {text-transform: capitalize;}
  .add--fields .react-datepicker-wrapper {width: 100%;}
  .header-nav .user-icon {width: 20px;height: 20px;cursor: pointer;}
  .header-nav .notify { width: 18.33px; height: 20px;}
  p.MuiTypography-root.MuiTypography-body1 { font-size: 14px; font-weight: 500; font-style: normal; font-family: "Montserrat" !important; max-width: 100%;}

}


/* responsive css */
@media (max-width: 1600px) {

  .notification_page .form_search .form-control {width: 100%;}
  .setting-section .form_search .react-datepicker-wrapper .form-control {width: 150px;}
  
}

@media (max-width: 1307px) {

  .notification_page .ms_lg_auto { margin-left: inherit !important; }

}

@media (max-width: 1367px) {

  .form_search .form-control { width: 250px;}
  .popup_gape .MuiButtonBase-root svg,
  .react-confirm-alert .react-confirm-alert-body .react-confirm-alert-button-group svg { width: 18px;}
  .configure-section .MuiBox-root .add-new-field { margin-left: inherit;}

}

@media(max-width: 992px) {
  .form_search .form-control,
  .MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedStart {width: 100%;}
  .w-md-100,
  .overview_table .box-1 {width: 100%;}
  .maintenance_history .MuiBox-root .add-new-field {margin-left: auto;}
  .configure-section .MuiPaper-root {padding: 20px;}
  .configure_page.configure-section .MuiPaper-root,
  .setting_page.configure-section .MuiPaper-root,
  .notification_page.pt-2,
  .pagination_top .configure-section .MuiToolbar-root > .MuiBox-root{padding: 0 !important;}
  .overview_table .MuiBox-root .pt_20,
  .overview_table .MuiToolbar-root > .MuiBox-root{ padding-top: 0px !important;}

}


@media (max-width: 767px) {

  .setting_page .MuiCollapse-root .MuiInputBase-input,
  .setting_page .MuiToolbar-root .MuiCollapse-wrapper .MuiCollapse-wrapperInner,
  .configure_page .MuiCollapse-root .MuiInputBase-input,
  .configure_page .MuiToolbar-root .MuiCollapse-wrapper .MuiCollapse-wrapperInner { width: 100%;}
  .setting_page .MuiCollapse-root .MuiFormControl-root,
  .configure_page .MuiCollapse-root .MuiFormControl-root { max-width: 100%; min-width: 100%;}
  .breadcrumb-item.active { font-size: 24px; }
  .maintaine_history span,
  .maintaine_history h3 { font-size: 18px;  }
  .add--fields.add--entry:before { display: none;  }
  .setting_page .MuiToolbar-root>.MuiBox-root,
  .configure_page .MuiToolbar-root>.MuiBox-root { flex-wrap: wrap; justify-content: space-between; padding: 0;  }
  .setting_page .MuiToolbar-root>.MuiBox-root>.MuiCollapse-root,
  .setting_page .MuiToolbar-root>.MuiBox-root>.MuiBox-root,
  .configure_page .MuiToolbar-root>.MuiBox-root>.MuiCollapse-root,
  .configure_page .MuiToolbar-root>.MuiBox-root>.MuiBox-root {width: 50%;justify-content: start;}
  .setting_page .MuiToolbar-root .MuiBox-root>.MuiButtonBase-root,
  .configure_page .MuiToolbar-root .MuiBox-root>.MuiButtonBase-root { margin: 0px 0 0 0px !important; width: auto !important;}
  .configure_page .MuiToolbar-root>.MuiBox-root .w-md-auto { width: 49%;}
  .configure_page .MuiToolbar-root>.MuiBox-root>.MuiCollapse-root,
  .setting_page .MuiToolbar-root>.MuiBox-root>.MuiCollapse-root { order: 3; width: 100%;}
  .setting_page .MuiToolbar-root>.MuiBox-root { padding-top: 0; gap: 13px;}
  .maintaine_history span {height: auto !important;}
  .configure-section .md-grid-cols-2.sm-grid-cols-2{  
    display: grid !important;
    grid-template-columns: repeat(1,minmax(0,1fr));
  }

}

@media (max-width: 575px) {

  .main-listing {margin-bottom: 45px;}
  .main-listing .breadcrumb{padding: 0px 0px 10px;}
  .configure-section .MuiPaper-root{padding: 10px 10px 10px !important;}
  .main-listing,
  .top-header .logo h2,
  .setting_page .MuiToolbar-root .MuiBox-root>.MuiButtonBase-root,
  .setting_page .MuiToolbar-root>.MuiBox-root>.MuiCollapse-root,
  .setting_page .MuiToolbar-root>.MuiBox-root>.MuiBox-root,
  .configure_page .MuiToolbar-root .MuiBox-root>.MuiButtonBase-root,
  .configure_page .MuiToolbar-root>.MuiBox-root>.MuiCollapse-root,
  .configure_page .MuiToolbar-root>.MuiBox-root>.MuiBox-root,
  .configure_page .MuiToolbar-root>.MuiBox-root .w-md-auto,
  .sidebar,
  .maintenance_history .MuiToolbar-root .MuiBox-root .MuiButtonBase-root.MuiButton-root{ width: 100%; }
  .sidebar { position: fixed; bottom: 0; z-index: 1000; border: none; }
  .sidebar .nav .nav-link { padding: 13px 15px !important;}
  .sidebar .nav .nav-item img,
  .sidebar .nav .nav-link.active img { opacity: 1 !important;}
  .sidebar .nav .nav-link img { opacity: 0.5;}
  .top-header .logo h2 { padding: 10px 10px; font-size: 20px;}
  .pagination_top .MuiTablePagination-root,
  .maintenance_history .MuiTablePagination-root {right: -17px;top: -52px;}
  .top-bar .logo img {height: 30px;width: 145px;}
  .pagination_top .MuiSelect-select.MuiTablePagination-select,
  .maintenance_history .MuiSelect-select.MuiTablePagination-select {padding: 3px 26px 3px 10px !important;}
  .pagination_top .MuiTablePagination-actions,
  .maintenance_history .MuiTablePagination-actions {margin-left: 0rem !important;}
  .pagination_top .MuiTablePagination-actions .MuiButtonBase-root,
  .maintenance_history .MuiTablePagination-actions .MuiButtonBase-root,
  .pagination_top .MuiTablePagination-actions .MuiButtonBase-root svg,
  .maintenance_history .MuiTablePagination-actions .MuiButtonBase-root svg { width: 24px; height: 24px; }
  .css-6vypie-MuiInputBase-root-MuiTablePagination-select { margin: 0 0.4rem 0 1ch !important; }
  .pagination_top .MuiTablePagination-actions .MuiButtonBase-root,
  .maintenance_history .MuiTablePagination-actions .MuiButtonBase-root { margin: 5px 4px; }
  .maintaine_history span,
  .maintaine_history h3 { font-size: 16px; width: 100%; text-align: left; display: flex; justify-content: center; margin: 0 !important; }
  .popup_gape .MuiDialogContent-root .MuiStack-root { min-width: auto; }
  .title-select,
  .add--fields .col-form-label,
  .title-select,
  .add--user .col-form-label { font-size: 14px; }
  .breadcrumb-item.active { font-size: 20px; line-height: 30px; }
  .setting_page .MuiToolbar-root>.MuiBox-root>.MuiBox-root,
  .configure_page .MuiToolbar-root .w-md-auto { margin-top: 0px; width: auto; }
  .notification-counter { right: -4px; }
  .add--fields.add--entry .MuiDialogActions-root { justify-content: center; padding-bottom: 0px; }
  .main-listing,
  .top-bar .top-header,
  .setting__page,
  .configuration__page { padding: 10px !important;}
  .popup-header { min-height: 45px; height: 45px; }
  .popup-header h2 { line-height: 3px; }
  .header-nav .nav-profile { display: none !important; }
  body .gap-lg-3, .gap-2{ gap: 10px !important; width: 100% !important; }
  .setting__page,
  .css-15hy2uy-MuiTypography-root{ padding-top: 10px !important; padding-bottom: 10px !important; }
  .configure_page .MuiToolbar-root>.MuiBox-root,
  .setting_page .MuiToolbar-root>.MuiBox-root { gap: 10px !important; }
  .maintenance_history .MuiToolbar-root > .MuiBox-root{ padding-top: 0; }
  .maintenance_history .MuiTablePagination-root{  top: -108px !important; }
  .overview_table{margin-bottom: 10px;}
  .configure-section .md-grid-cols-2{   display: grid !important; grid-template-columns: repeat(1,minmax(0,1fr)); }
  .pagination_top .MuiToolbar-root, 
  .maintenance_history .MuiToolbar-root{  min-height: inherit !important; } 
  .maintenance_history .maintaine_history{margin: 0 !important;}
}

@media (max-width: 500px) {

  .setting-section .form_search .react-datepicker-wrapper .form-control,
  .notification_page .form_search .date_icon,
  .notification_page .form_search [type="time"].form-control,
  .notification_page .ms_lg_auto{ width: 100%; }
  .notification_page .line { display: flex !important; justify-content: center; width: 100%; height: 5px; }

}

@media (max-width: 420px) {
  .top-header .logo h2 { width: 121px; text-align: left !important; }
  .flex_wrap_420 { flex-wrap: wrap; }
  .react-confirm-alert,
  .react-confirm-alert-body { max-width: 290px; }
}


@media (max-width: 375px) {

  .setting_page .MuiToolbar-root .MuiBox-root>.MuiButtonBase-root,
  .configure_page .MuiToolbar-root .MuiBox-root>.MuiButtonBase-root,
  .overview_table .box-2 .MuiButtonBase-root,
  .notification_page .line { max-width: 100% !important; width: 100% !important; }
  .setting_page .MuiToolbar-root>.MuiBox-root { padding-top: 0; gap: 13px; }
  .top-bar .logo img { height: auto; width: 148px; margin: 0; top: 0; }
  .MuiTablePagination-root .MuiTablePagination-actions > button:first-child,
  .MuiTablePagination-root .MuiTablePagination-actions > button:last-child{ display: none; }

}

@media (max-width: 332px) {

  .maintenance_history .MuiToolbar-root{ display: none !important; }

}

.options-block { display: flex; align-items: center;}
.option-delete-btn { margin-top: 0px !important; cursor: pointer;}
.Mui-active, .Mui-active svg { color: #2C82D8 !important; }